<template>
  <rb-modal
    :has-featured-icon="true"
    featured-icon-name="info-circle"
    :title="$t('sidebar.redirect_info_modal.title')"
    :primary-action-text="
      primaryButtonText !== ''
        ? $t(`sidebar.redirect_info_modal.primary_button.${primaryButtonText}`)
        : ''
    "
    :secondary-action-text="$t('sidebar.redirect_info_modal.secondary_button')"
    :size="500"
    featured-icon-color="info"
    @on-primary-action="onPrimaryAction"
    @secondary-action="onClose"
    @closed="onClose"
  >
    <p class="text-neutral-700">
      {{ $t('sidebar.redirect_info_modal.text') }}
    </p>
    <rb-checkbox
      v-model="doNotShowThisAgainInternal"
      class="mt-4"
      :label="$t('sidebar.redirect-info-modal.checkbox.label')"
    />
  </rb-modal>
</template>

<script setup lang="ts">
const doNotShowThisAgainInternal = ref<boolean>(false);

const startpageStore = useStartpageStore();
const { showRedirectInfoModal, doNotShowRedirectInfoModalAgain, primaryButtonText } =
  storeToRefs(startpageStore);

const onPrimaryAction = () => {
  if (doNotShowThisAgainInternal.value) {
    doNotShowRedirectInfoModalAgain.value = true;
  }
  startpageStore.redirectToClassicDashboard();
};

const onClose = () => {
  showRedirectInfoModal.value = false;
  if (doNotShowThisAgainInternal.value) {
    doNotShowRedirectInfoModalAgain.value = true;
  }
};
</script>
