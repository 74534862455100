const redirectionMap = [
  {
    from: /^\/$/g,
    to: '/boxes',
  },
  {
    from: /^\/subscriptions(\/.*)?$/g,
    to: '/vertraege',
  },
  {
    from: /^\/invoices(\/.*)?$/g,
    to: '/rechnungen',
  },
  {
    from: /^\/payment-methods(\/)?$/g,
    to: '/zahlarten',
  },
  {
    from: /^\/user\/profile(\/)?$/g,
    to: '/profil',
  },
  {
    from: /^\/user\/security(\/)?$/g,
    to: '/profil-einstellungen',
  },
  {
    from: /^\/boxes(\/.*)?$/g,
    to: '/boxes',
  },
];

export const useRedirectToOldDashboard = computed<string>(() => {
  const route = useRoute();
  const nuxtConfig = useRuntimeConfig();
  const { path } = route;
  let url = '';

  redirectionMap.forEach((redirect) => {
    if (redirect.from.test(path)) {
      url = `${nuxtConfig.public.oldDashboardUrl}${redirect.to}`;
    }
  });
  return url;
});
