<template>
  <div>
    <div class="flex justify-between">
      <div class="flex space-x-2">
        <base-user-profile-picture size="md" />
        <div>
          <rb-link
            v-if="!hasName"
            size="sm"
            type="neutral"
            trailing-icon-name="plus"
            href="/user/profile"
            @click.prevent="navigateTo('/user/profile')"
          >
            {{ $t('sidebar.user_area.add_name') }}
          </rb-link>
          <div
            v-else
            class="max-w-[10rem] truncate text-md font-semibold text-neutral-700"
            :title="name"
          >
            {{ name }}
          </div>
          <div class="max-w-[10rem] truncate font-text text-xs text-neutral-600" :title="email">
            {{ email }}
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <div v-if="open" class="mt-1 space-y-2">
              <div
                v-if="username"
                class="flex items-center gap-x-1 font-text text-xs text-neutral-600"
              >
                <rb-tooltip :title="$t('sidebar.user_area.username.tooltip')">
                  {{ username }}
                </rb-tooltip>

                <base-copy-to-clipboard
                  button-hierarchy="plain"
                  button-type="neutral"
                  :text-to-copy="username"
                  :tooltip-title="$t('sidebar.user_area.username.copy.action')"
                  :notification-title="$t('sidebar.user_area.username.copied.title')"
                />
              </div>
              <div
                v-if="userId"
                class="flex items-center gap-x-1 font-text text-xs text-neutral-600"
              >
                <rb-tooltip :title="$t('sidebar.user_area.user_id.tooltip')">
                  {{ userId }}
                </rb-tooltip>

                <base-copy-to-clipboard
                  button-hierarchy="plain"
                  button-type="neutral"
                  :text-to-copy="userId"
                  :tooltip-title="$t('sidebar.user_area.user_id.copy.action')"
                  :notification-title="$t('sidebar.user_area.user_id.copied.title')"
                />
              </div>
            </div>
          </transition>
        </div>
      </div>
      <rb-button
        :alt-text="$t('sidebar.accordion_button.alt_text')"
        icon="only"
        :icon-name="open ? 'chevron-up' : 'chevron-down'"
        hierarchy="tertiary"
        size="sm"
        type="neutral"
        class="mt-1"
        @click="toggle"
      />
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <ul v-if="open">
        <rb-divider class="my-2" />

        <base-layout-navigation-button
          name="User profile"
          size="sm"
          icon="user"
          to="/user/profile"
        />

        <base-layout-navigation-button
          name="Security"
          size="sm"
          icon="settings"
          to="/user/security"
        />

        <rb-divider class="my-2" />

        <base-layout-navigation-button
          name="Offers"
          size="sm"
          icon="heart-hand"
          :to="$t('sidebar.menu_item.offers.url')"
          :is-external="true"
        />

        <base-layout-navigation-button
          name="Feedback"
          size="sm"
          icon="message-smile-square"
          :to="$t('sidebar.menu_item.feedback.url')"
          :is-external="true"
        />
        <rb-divider class="my-2" />

        <rb-navigation-button
          id="logout"
          icon-name="log-out"
          size="sm"
          type="error"
          :icon-alt-text="$t('header.logout')"
          :is-link="false"
          @click="() => authStore.logout()"
        >
          {{ $t('header.logout') }}
        </rb-navigation-button>
      </ul>
    </transition>
  </div>
</template>

<script setup lang="ts">
const authStore = useAuthStore();

const props = defineProps({
  name: {
    type: String as PropType<string>,
    required: false,
    default: '',
  },
  email: {
    type: String as PropType<string>,
    required: false,
    default: '',
  },
  userId: {
    type: Number as PropType<number>,
    required: false,
    default: 0,
  },
  username: {
    type: String as PropType<string | null>,
    required: false,
    default: '',
  },
});
const emit = defineEmits<{
  /**
   * Provides the event `navigateToPage` which allows to overwrite the `onClick` event of the link if it's default behavior is prevented.
   *
   * @event linkClick
   */
  (e: 'toggled', open: boolean): void;
}>();

const open = ref<boolean>(false);

const route = useRoute();

const toggle = () => {
  open.value = !open.value;
  emit('toggled', open.value);
};

watch(
  () => route.path,
  () => {
    if (open.value && !route.path.startsWith('/user')) {
      open.value = false;
    }
    if (!open.value && route.path.startsWith('/user')) {
      open.value = true;
      emit('toggled', open.value);
    }
  },
  {
    immediate: true,
  },
);

const hasName = computed<boolean>(() => props.name.trim() !== '');
</script>
