<template>
  <div class="flex justify-center bg-light">
    <rb-footer class="container">
      <rb-footer-link
        v-for="item in footerLinks"
        :key="item.id"
        :href="item.href"
        @click.prevent="() => navigateTo(item.href, { external: true, open: { target: '_blank' } })"
        >{{ $t(`footer.link.${item.name.replace(' ', '_').toLowerCase()}.name`) }}</rb-footer-link
      >
      <rb-popover>
        <template #popover>
          <div class="max-w-xs space-y-4 p-2">
            <div class="text-lg font-semibold text-neutral-700">
              {{ $t('footer.link.contact.popover.title') }}
            </div>
            <p class="font-medium text-neutral-700">
              <i18n-t keypath="footer.link.contact.popover.supporting_text">
                <template #email>
                  <rb-link
                    :href="`mailto:${$t('footer.support_email')}`"
                    external
                    target="_blank"
                    size="sm"
                  >
                    {{ $t('footer.support_email') }}
                  </rb-link>
                </template>
              </i18n-t>
            </p>
            <rb-button class="w-full" size="sm" hierarchy="secondary" @click="openIntercom">
              {{ $t('footer.link.contact.popover.action') }}</rb-button
            >
          </div>
        </template>

        <rb-button class="w-fit !text-neutral-600" hierarchy="plain" type="neutral">{{
          $t('footer.link.contact')
        }}</rb-button>
      </rb-popover>
    </rb-footer>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();
const footerLinks = [
  {
    id: 1,
    name: 'Legal',
    href: t('footer.link.legal.url'),
  },
  {
    id: 2,
    name: 'Privacy',
    href: t('footer.link.privacy.url'),
  },
  {
    id: 3,
    name: 'Terms',
    href: t('footer.link.terms.url'),
  },
  {
    id: 4,
    name: 'Careers',
    href: t('footer.link.careers.url'),
  },
  {
    id: 5,
    name: 'Help Center',
    href: t('footer.link.help_center.url'),
  },
];

const openIntercom = () => {
  if (window.Intercom) {
    window.Intercom('showNewMessage');
    modalOpen.value = false;
  }
};
</script>
<script lang="ts">
export default {
  name: 'BaseFooter',
};
</script>
