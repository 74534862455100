<template>
  <rb-sidebar
    :on-close-sidebar="() => layoutStore.toggleSidebar()"
    :close-button-alt-text="$t('general.button.close')"
    :on-logo-click="() => navigateTo('/')"
  >
    <rb-button
      v-feature="'BoxPages'"
      class="mb-6 inline-flex lg:hidden"
      hierarchy="secondary"
      size="sm"
      @click="
        () => {
          layoutStore.closeSidebar();
          navigateTo('/boxes/create-new');
        }
      "
    >
      {{ $t('header.button.create_new_box') }}
    </rb-button>
    <rb-sidebar-section :name="$t('sidebar.category.general')">
      <base-layout-navigation-button name="Home" icon="home" />
      <div v-feature="'BoxPages'">
        <base-layout-navigation-button
          name="Dashboard"
          icon="bar-chart"
          to="/dashboard"
          has-sub-menu
          sub-menu-route="/boxes"
        >
          <template #sub-menu>
            <base-layout-navigation-button
              name="Boxes"
              to="/boxes"
              :is-sub-menu-navigation-button="true"
            />
            <rb-tooltip
              v-feature="'BoxPages'"
              class="w-full hover:z-10"
              :title="$t('sidebar.button.templates.tooltip')"
              :auto-position="false"
              position="bottom"
            >
              <rb-navigation-button
                href="/vorlagen"
                @click="() => onClassicDashboardRedirect('/vorlagen', 'templates')"
              >
                {{ $t('sidebar.menu_item.templates') }}
              </rb-navigation-button>
            </rb-tooltip>
            <rb-tooltip
              v-feature="'BoxPages'"
              class="w-full"
              :title="$t('sidebar.button.domains.tooltip')"
              :auto-position="false"
              position="top"
            >
              <rb-navigation-button
                href="/domains"
                @click="() => onClassicDashboardRedirect('/domains', 'domains')"
              >
                {{ $t('sidebar.menu_item.domains') }}
              </rb-navigation-button>
            </rb-tooltip>
            <rb-tooltip
              v-feature="'BoxPages'"
              class="w-full"
              :title="$t('sidebar.button.emails.tooltip')"
              :auto-position="false"
              position="top"
            >
              <rb-navigation-button
                href="/mails"
                @click="() => onClassicDashboardRedirect('/mails', 'emails')"
              >
                {{ $t('sidebar.menu_item.emails') }}
              </rb-navigation-button></rb-tooltip
            >
          </template>
        </base-layout-navigation-button>
      </div>
    </rb-sidebar-section>
    <rb-sidebar-section :name="$t('sidebar.category.orders')">
      <base-layout-navigation-button name="Invoices" icon="clock-fast-forward" to="/invoices" />
      <base-layout-navigation-button name="Subscriptions" icon="clipboard" to="/subscriptions" />
      <div v-feature="'PaymentSettings'">
        <base-layout-navigation-button
          name="Payment methods"
          icon="pie-chart"
          to="/payment-methods"
        />
      </div>
    </rb-sidebar-section>

    <template #menu-items-bottom>
      <rb-sidebar-section>
        <base-layout-navigation-button
          v-feature="'BoxPages'"
          name="Partner area"
          icon="cursor-click"
          :to="`${nuxtConfig.public.oldDashboardUrl}/affiliate`"
          :is-external="true"
        />

        <div id="beamer-trigger">
          <rb-navigation-button
            icon-name="announcement"
            :icon-alt-text="$t('sidebar.menu_item.news.icon_alt_text')"
            :is-link="false"
            @click="checkForBeamer"
          >
            {{ $t('sidebar.menu_item.news') }}
          </rb-navigation-button>
        </div>
        <rb-tooltip
          class="w-full"
          :title="$t('sidebar.menu_item.classic_view.tooltip.title')"
          :supporting-text="$t('sidebar.menu_item.classic_view.tooltip.supporting_text')"
        >
          <base-layout-navigation-button
            name="classic_view"
            icon="refresh-ccw"
            :to="useRedirectToOldDashboard"
          />
        </rb-tooltip>
      </rb-sidebar-section>
    </template>
    <template #account-info>
      <div class="mb-4 text-xs font-semibold leading-6 text-gray-600">Account</div>
      <base-layout-account-accordion
        :name="name"
        :email="profile.email"
        :user-id="profile.trashboardId"
        :username="rbUsername"
        @toggled="toggled"
      />
    </template>
    <template #title="{ titleClasses }">
      <rb-link
        v-if="name.trim() === ''"
        size="sm"
        type="neutral"
        trailing-icon-name="plus"
        href="/user/profile"
        @click.prevent="navigateTo('/user/profile')"
      >
        {{ $t('sidebar.user_area.add_name') }}
      </rb-link>
      <p :class="titleClasses">{{ name }}</p>
    </template>
    <base-beamer-blocked-modal v-model="showBeamerBlockedModal" />
    <base-redirect-info-modal
      v-if="!doNotShowRedirectInfoModalAgain"
      v-model="showRedirectInfoModal"
    />
  </rb-sidebar>
</template>

<script setup lang="ts">
import { useRedirectToOldDashboard } from '~/utils/old-dashboard-redirect';

const nuxtConfig = useRuntimeConfig();

const layoutStore = useLayoutStore();
const startpageStore = useStartpageStore();
const {
  showRedirectInfoModal,
  doNotShowRedirectInfoModalAgain,
  classicDashboardUrl,
  primaryButtonText,
} = storeToRefs(startpageStore);

const userStore = useUserStore();
const { name, profile } = storeToRefs(userStore);

const showBeamerBlockedModal = ref<boolean>(false);

const rbUsername = computed<string | null>(() => {
  const username = profile.value.attributes.find((item) => item.name === 'external_identifier');
  if (username && username !== undefined) {
    return username?.value;
  }
  return null;
});

const checkForBeamer = () => {
  if (window.Beamer === undefined || window.Beamer === null) {
    showBeamerBlockedModal.value = true;
  }
};

const toggled = (open: boolean) => {
  if (open) {
    nextTick(() => {
      const sidebarInner = document.getElementById('sidebar-outer');
      if (sidebarInner !== null) {
        sidebarInner.scrollTop = sidebarInner.scrollHeight;
      }
    });
  }
};

const onClassicDashboardRedirect = (url: string, name: string) => {
  classicDashboardUrl.value = url;
  primaryButtonText.value = name;

  if (!doNotShowRedirectInfoModalAgain.value) {
    showRedirectInfoModal.value = true;
  } else {
    startpageStore.redirectToClassicDashboard();
  }
};
</script>

<script lang="ts">
export default {
  name: 'BaseSidebar',
};
</script>
<style lang="css">
#lang-switch-sidebar > div > button {
  width: 100%;
}

#beamer-trigger .beamer_icon.active,
#beamer-trigger #beamerIcon.active {
  background-color: theme('colors.info.50') !important;
  color: theme('colors.info.700');
  height: 22px;
  width: 22px;
  line-height: 22px;
  font-size: theme('fontSize.xs');
  right: 12px;
  transform: translateY(50%);
}
</style>
