<template>
  <nuxt-link #default="{ isActive, navigate }" :to="to" custom :external="isExternal">
    <rb-navigation-button
      :current="isActive || subMenuActive"
      :href="to"
      :size="size"
      :icon-name="icon"
      :icon-alt-text="
        $t('sidebar.menu_item.' + name.replace(' ', '_').toLowerCase() + '.icon_alt_text')
      "
      :has-sub-menu="hasSubMenu"
      :sub-menu-collapsed="!subMenuActive"
      :is-sub-menu-navigation-button="isSubMenuNavigationButton"
      :is-external="isExternal"
      @click="
        !hasSubMenu
          ? isExternal
            ? navigateTo(to, { external: true, open: { target: '_blank' } })
            : sidebarNavigateTo(navigate)
          : false
      "
    >
      {{ $t('sidebar.menu_item.' + name.replace(' ', '_').toLowerCase()) }}
      <template #sub-menu>
        <slot name="sub-menu"></slot>
      </template>
    </rb-navigation-button>
  </nuxt-link>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import type { NavigationFailure } from 'vue-router';

const props = defineProps({
  name: {
    type: String as PropType<string>,
    default: 'Home',
  },
  to: {
    type: String as PropType<string>,
    default: '/',
  },
  isExternal: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  size: {
    type: String as PropType<string>,
    default: 'md',
  },
  icon: {
    type: String as PropType<string>,
    default: '',
  },
  hasSubMenu: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  subMenuRoute: {
    type: String as PropType<string>,
    default: '',
  },
  isSubMenuNavigationButton: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const layoutStore = useLayoutStore();

const route = useRoute();

const subMenuActive = ref<boolean>(false);
function sidebarNavigateTo(navigate: (e?: MouseEvent) => Promise<void | NavigationFailure>) {
  navigate();
  layoutStore.closeSidebar();
}

watch(
  () => route.path,
  () => {
    if (props.hasSubMenu) {
      if (subMenuActive.value && !route.path.startsWith(props.subMenuRoute)) {
        subMenuActive.value = false;
      }
      if (!subMenuActive.value && route.path.startsWith(props.subMenuRoute)) {
        subMenuActive.value = true;
      }
    }
  },
  { immediate: true },
);
</script>
